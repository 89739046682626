<template>
  <div class="landing-page">
    <AppBar />
    <div class="content">
      <div class="chat-box">
        <div class="chat-messages" v-for="message in messages" :key="message.id">
          <div class="message">
            <strong>{{ message.title }}</strong>: {{ message.url }}
          </div>
        </div>
      </div>
    </div>
    <MessageEntry v-if="isAuthenticated" @submit="submitPrompt" v-model="input" />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import axios from 'axios';
import AppBar from './AppBar.vue';
import MessageEntry from './MessageEntry.vue';

export default {
  name: 'LandingPage',
  components: {
    AppBar,
    MessageEntry
  },
  setup() {
    const { isAuthenticated, user, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

    const greeting = computed(() => 
      isAuthenticated.value ? `Welcome back to Oiso.io, ${user.value.name}` : 'Welcome to Oiso.io'
    );

    const handleAuth = () => {
      if (isAuthenticated.value) {
        logout({ returnTo: window.location.origin });
      } else {
        loginWithRedirect();
      }
    };

    const input = ref('');
    const messages = ref([]);
    const errorMessage = ref('');

    const submitPrompt = async () => {
      if (!input.value.trim()) return;
      const data = {
        prompt: input.value
      };
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post('/api/discover', data, {
          //params: { query_id: 'placeholder query_id' },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        messages.value.push(...response.data.responses);
        input.value = '';
        errorMessage.value = '';
      } catch (error) {
        if (error.response && error.response.status === 422) {
          errorMessage.value = 'Validation error: Please check your input.';
        } else {
          errorMessage.value = 'An error occurred. Please try again.';
        }
      }
    };

    return {
      greeting,
      handleAuth,
      isAuthenticated,
      input,
      messages,
      submitPrompt
    };
  }
};
</script>

<style scoped>
.landing-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.chat-box {
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
}

.message {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.textarea {
  width: 100%;
  margin-bottom: 10px;
}
</style>
