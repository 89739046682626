<template>
    <div class="message-entry">
      <textarea
        v-model="message"
        class="textarea textarea-bordered"
        placeholder="Type your message..."
        @keydown.ctrl.enter="submit"
      ></textarea>
      <button class="btn btn-primary" @click="submit">Send</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MessageEntry',
    props: {
      modelValue: String
    },
    emits: ['update:modelValue', 'submit'],
    data() {
      return {
        message: this.modelValue
      };
    },
    watch: {
      modelValue(newVal) {
        this.message = newVal;
      },
      message(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    },
    methods: {
      submit() {
        this.$emit('submit');
      }
    }
  };
  </script>
  
  <style scoped>
  .message-entry {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
  }
  
  .textarea {
    flex: 1;
    margin-right: 1rem;
  }
  
  .btn-primary {
    margin-left: 1rem;
  }
  </style>
  