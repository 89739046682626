<template>
    <div class="navbar bg-base-100 shadow-xl rounded-box">
      <div class="navbar-start">
        <span>Oiso.io</span>
      </div>
      <div class="navbar-end">
        <button v-if="!isAuthenticated" class="btn btn-primary" @click="loginWithRedirect">Login</button>
        <img v-else :src="user.picture" alt="Avatar" class="avatar" @click="toggleMenu" />
        <div v-if="showMenu" class="dropdown">
          <button class="btn btn-primary" @click="logout">Logout</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useAuth0 } from '@auth0/auth0-vue';
  
  export default {
    name: 'AppBar',
    setup() {
      const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
      const showMenu = ref(false);
  
      const toggleMenu = () => {
        showMenu.value = !showMenu.value;
      };
  
      return {
        isAuthenticated,
        user,
        loginWithRedirect,
        logout,
        showMenu,
        toggleMenu
      };
    }
  };
  </script>
  
  <style scoped>

  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    top: 50px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }
  </style>
  